import { CognitoUser } from 'amazon-cognito-identity-js';
import UserPool from "../UserPool"

const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;

export function initFacebookSdk() {
    return new Promise(resolve => {
        // wait for facebook sdk to initialize before starting the react app
        //window is required to make Facebook SDK (FB.) available to the whole window
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: facebookAppId,
                cookie: true,
                xfbml: true,
                version: 'v15.0'
            });

            // auto authenticate with the api if already logged in with facebook

            window.FB.getLoginStatus(function (authResponse) {   // See the onlogin handler
                if (authResponse) {
                    statusChangeCallback(authResponse)
                    resolve();
                } else {
                    resolve();
                }

            });

        };


        // load facebook sdk script
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));


    });

    function statusChangeCallback(response) {  // Called with the results from FB.getLoginStatus().
        console.log('statusChangeCallback');
        console.log(response);                   // The current login status of the person.
        if (response.status === 'connected') {   // Logged into your webpage and Facebook.
            console.log("connected")
            testAPI();
        } else {                                 // Not logged into your webpage or we are unable to tell.
            console.log("not connected")
            // document.getElementById('status').innerHTML = 'Please log ' +
            //     'into this webpage.';
        }
    }

    function testAPI() {                      // Testing Graph API after login.  See statusChangeCallback() for when this call is made.
        console.log('Welcome!  Fetching your information.... ');
        window.FB.api('/me', function (response) {
            console.log('Successful login for: ' + response.name);
            //   document.getElementById('status').innerHTML =
            //     'Thanks for logging in, ' + response.name + '!';
        });
    }
}

// export default getSession = async () => {
//     return await new Promise((resolve, reject) => {
//         const user = UserPool.getCurrentUser();
//         if (user) {
//             user.getSession((err, session) => {
//                 if (err) {
//                     reject();
//                 } else {
//                     resolve(session)
//                 }
//             }
//             )
//         }
//     })
// }
