import React from "react";
import "./Sidenav.css";
import { useNavigate, NavLink } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import UserPool from "../../UserPool"


function Sidenav() {
    const navigate = useNavigate()

    const logout = () => {
        const user = UserPool.getCurrentUser()
        if (user) {
            user.signOut()
            window.location.reload()
        }
    }

    return (
        <div className="sidenav">
            <img
                className="sidenav__logo"
                src="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FSecondary_Logo_Color.png?alt=media&token=bc746d93-e66f-4255-9950-f63ec15527a8"
                alt="Instagram Logo"
            />
            <hr className="sidenav__hr" />

            <div className="sidenav__buttons">
                <NavLink to='/' className="sidenav__button">
                    {({ isActive, isPending }) => (
                        <>
                            <img className={isActive ? "imgactive" : "imghidden"} src={"https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FDashboard%20active.svg?alt=media&token=b6d12e9a-0235-49cc-ac4d-e783ac6dff16"} />
                            <img className={isActive ? "imghidden" : "imgactive"} src={"https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FDashboard%20passive.svg?alt=media&token=ab781b4a-66e9-458a-bc28-5855b86e1192"} />
                            <span className={isActive ? "sidenav__buttonactive" : "sidenav__buttonpassive"}>Dashboard</span>
                        </>
                    )}

                </NavLink>
                {/* <NavLink
                    to={{
                        pathname: "/review/17866894250945721", // inject code value into template
                    }}
                    className="sidenav__button">
                    {({ isActive, isPending }) => (
                        <>
                            <img className={isActive ? "imgactive" : "imghidden"} src={"https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FReviews%20active.svg?alt=media&token=9126eb8d-00f1-44a7-a529-1105a53a3f8c"} />
                            <img className={isActive ? "imghidden" : "imgactive"} src={"https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FReviews%20passive.svg?alt=media&token=67e63584-0e5b-4d3c-a40c-b4973726cba4"} />
                            <span className={isActive ? "sidenav__buttonactive" : "sidenav__buttonpassive"}>Review</span>
                        </>
                    )}
                </NavLink> */}
                {/* <NavLink to='/settings' className="sidenav__button">
                    {({ isActive, isPending }) => (
                        <>
                            <img className={isActive ? "imgactive" : "imghidden"} src={"https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FSettings%20active.svg?alt=media&token=5cb331ba-4b21-4200-bb8b-ed268414d424"} />
                            <img className={isActive ? "imghidden" : "imgactive"} src={"https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FSettings%20passive.svg?alt=media&token=ec813f88-e0df-4b29-9d6b-d712d6674fcf"} />
                            <span className={isActive ? "sidenav__buttonactive" : "sidenav__buttonpassive"}>Settings</span>
                        </>
                    )}
                </NavLink> */}
                <div className="sidenav__button" onClick={logout}>
                    <LogoutIcon />
                    <span className={"sidenav__buttonpassive"}>Logout</span>
                </div>
            </div>
        </div >
    );
}

export default Sidenav;