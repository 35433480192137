import React, { useState, useContext } from 'react'
import { AppContext } from './context';
import "./Homepage.css";
import Sidenav from "./components/navigation/Sidenav";
import Timeline from "./components/timeline/Timeline";
import Login from "./login"


import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { DynamoDBDocumentClient } from "@aws-sdk/lib-dynamodb";

const accessKeyId = process.env.REACT_APP_DYNAMO_KEY_ID;
const secretAccessKey = process.env.REACT_APP_DYNAMO_SECRET_KEY;


const client = new DynamoDBClient({
    region: "us-east-1",
    credentials: {
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey
    }
});
const docClient = DynamoDBDocumentClient.from(client);




function Homepage({ loaded, loggedIn, handleLoginSuccess }) {

    const [mode, setMode] = useState("login")

    const context = useContext(AppContext)


    console.log({ loaded, loggedIn })


    return (
        <div className="test">
            {/* {(!loaded || !context.state.response?.posts) */}
            {(!loaded)
                ? (
                    <div>
                        <p>Loading</p>
                    </div>
                ) : (
                    <div className="homepage">
                        {!loggedIn
                            ?
                            <div className="homepage__loginContainer">
                                {mode == "login"
                                    ?
                                    <Login />
                                    : null
                                }

                            </div>
                            :
                            <>
                                <div className="homepage__navWraper">
                                    <Sidenav />
                                </div>
                                <div className="homepage__timeline">
                                    <Timeline />
                                </div>
                            </>
                        }
                    </div>
                )}
        </div>
    );
}

export default Homepage;