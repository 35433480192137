import React, { CSSProperties, useEffect, useState, useContext } from "react";
import moment from 'moment';
import { AppContext } from '../../context';
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { DynamoDBDocumentClient, GetCommand } from "@aws-sdk/lib-dynamodb";
import { SFNClient, StartExecutionCommand } from "@aws-sdk/client-sfn"; // ES Modules import
import RefreshIcon from '@mui/icons-material/Refresh';
import { Grid } from 'react-loading-icons'

import './header.css'

export default function Header() {
    const [parsedAt, setParsedAt] = useState("")
    const [loading, setLoading] = useState(false)
    const [parsedAtRaw, setParsedAtRaw] = useState("")

    const context = useContext(AppContext)

    useEffect(() => {
        async function main() {
            if (!context.state?.session) return;

            const uid = context.state.session.idToken.payload["custom:uid"]

            const command = new GetCommand({
                TableName: "sentiment-users",
                Key: {
                    pk: `USER#${uid}`,
                    sk: `#PROFILE#${uid}`
                }
            });

            const response = await docClient.send(command);

            var mydate = moment.utc(response.Item.parsed_at).format('MMMM DD h:mm:ss a')
            console.log(mydate)
            setParsedAtRaw(response.Item.parsed_at)
            setParsedAt(mydate)

        }
        main()

    }, [context.state?.session])

    const accessKeyId = process.env.REACT_APP_DYNAMO_KEY_ID;
    const secretAccessKey = process.env.REACT_APP_DYNAMO_SECRET_KEY;
    const client = new DynamoDBClient({
        region: "us-east-1",
        credentials: {
            accessKeyId: accessKeyId,
            secretAccessKey: secretAccessKey
        }
    });
    const docClient = DynamoDBDocumentClient.from(client);

    async function refreshFeed() {

        const accessKeyId = process.env.REACT_APP_DYNAMO_KEY_ID;
        const secretAccessKey = process.env.REACT_APP_DYNAMO_SECRET_KEY;
        const arn = process.env.REACT_APP_SENTIMENT_SINGLE_ARN

        const client = new SFNClient({
            region: "us-east-1",
            credentials: {
                accessKeyId: accessKeyId,
                secretAccessKey: secretAccessKey
            }
        });

        const uid = context.state.session.idToken.payload["custom:uid"]
        const id = `{\"id\":\"${uid}\"}`
        const input = { // StartExecutionInput
            stateMachineArn: arn, // required
            input: id,
        };
        const command = new StartExecutionCommand(input);
        const response = await client.send(command);
        console.log(response)

        function oldFashion() {
            for (let i = 0; i < 10; i++) {
                schedule(i, i);
            }
            schedule("Finish", 10);
        }
        oldFashion();

        function schedule(msg, i) {
            setTimeout(() => {
                console.log(msg);
                callDynamo(i + 1)
            }, i * 5000);
        }

        setLoading(true)
    }

    async function callDynamo(attempt) {
        //3 Replaced FB retrieval
        // const uid = context.state.response.authResponse.userID
        // const access_token = context.state.response.authResponse.accessToken
        const uid = context.state.session.idToken.payload["custom:uid"]

        const command = new GetCommand({
            TableName: "sentiment-users",
            Key: {
                pk: `USER#${uid}`,
                sk: `#PROFILE#${uid}`
            }
        });

        const res = await docClient.send(command);
        let response = res.Item
        console.log("attempt" + attempt, response)
        if (response.parsed_at != parsedAtRaw) {
            window.location.reload()
        }
    }

    if (!context.state?.response) {
        return (
            <div>Loading</div>
        )
    }

    const override = {
        display: "flex",
        margin: "auto",
    };

    return (
        <div className="timeline__header">
            <p className="timeline__header__text">Hello {context.state.session.idToken.payload.given_name}!</p>
            {/* <div className="timeline__header__picContainer">
                <p className="timeline__header__picText">{context.state.session.idToken.payload.given_name} {context.state.session.idToken.payload.family_name}</p>
                <img className="timeline__header__profilePic" src={context.state.response.url} />
            </div> */}
            <div className="timeline__header__picContainer">
                <div className="timeline__parsedAtOuterContainer">
                    <div className="timeline__parsedAtContainer">
                        <p className="timeline__parsedAt">Last refreshed at: </p>
                        <p className="timeline__parsedAtTime">{parsedAt}</p>
                    </div>
                    {loading
                        ?
                        <div className="header__refreshIcon__parsedAtLoading">
                            <Grid height="25" width="25" fill="black" stroke="#EFF3FD" strokeOpacity={.8} speed={.75} />
                        </div>
                        :
                        <div className="header__refreshIcon__parsedAt" onClick={refreshFeed}>
                            <RefreshIcon
                                sx={{
                                    color: "black", fontSize: 40
                                }}
                            />
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}
