import React from 'react'
import { Grid } from 'react-loading-icons'

import './spinner.css'

export default function spinner() {
    return (
        <div className="spinnerContainer">
            <div className='spinnerInnerContainer'>
                <div className="spinnerCentredContainer">
                    <Grid height="100" width="100" fill="#0fd697" stroke="#EFF3FD" strokeOpacity={.8} speed={.75} />
                </div>
                <p className="spinnerWorkingText">Working ...</p>
            </div>
        </div>
    )
}
