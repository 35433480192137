import React, { useEffect, useState, useContext } from "react";
import "./Post.css";
import { NavLink } from "react-router-dom";

function Post({ user, postImage, likes, timestamp, score, requiring_review, media_id }) {

    function getLogo() {

        var trendDescriptor = ""
        if (score >= 75) {
            trendDescriptor = "improving"
        } else if (score >= 50) {
            trendDescriptor = "declining"
        } else {
            trendDescriptor = "flat"
        }

        switch (trendDescriptor) {
            case "improving":
                return (<img
                    className="post__comments__logo"
                    src={"https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FHigh%20score.svg?alt=media&token=8d77d9b9-3c38-489b-9f7f-3014f7eba487"}
                    alt="Instagram Logo"
                />)
                break;
            case "flat":
                return (<img
                    className="post__comments__logo"
                    src={"https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FNormal%20score.svg?alt=media&token=414b0425-f1be-46ed-8fbe-78cc4c9be8ca"}
                    alt="Instagram Logo"
                />)
                break;
            case "declining":
                return (<img
                    className="post__comments__logo"
                    src={"https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FLow%20score.svg?alt=media&token=c11a2280-91f2-4d98-b6e8-3865e40e15ec"}
                    alt="Instagram Logo"
                />)
                break;
            default:
                return
        }
    }

    function getTimeElapsed() {
        // const timeElapsed = (Math.floor((Date.now() - new Date(timestamp)) / 1000 / 60 / 60 / 24))
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const day = new Date(timestamp).getDate()
        const month = monthNames[new Date(timestamp).getMonth()]
        return (<>
            {day} {month}
        </>)
    }

    return (
        <NavLink to={`/review/${media_id}`} className="gallery-item" tabIndex="0">
            {/* <div className="gallery-item" tabIndex="0"> */}
            {/* <div className="post__header"> */}
            <div className="post__headerAuthor">
                <span>{getTimeElapsed()}</span>
            </div>
            <div className="post__image">
                <img src={postImage} alt="Post Image" />
            </div>
            <div className="post__footer">
                <div className="post__footerIcons">
                    <div className="post__iconsMain">
                        <div className="post__footer__iconContainer">
                            <p className="post__footer__textLikes">{likes || "0"} likes</p>
                        </div>

                        {/* <div className="post__footer__iconContainer">
                            {getLogo()}
                            <p className="post__footer__text">Sentiment score: {score || "n/a"}</p>
                        </div> */}
                        <div className="post__footer__iconContainer">
                            <img
                                className="post__comments__logo"
                                src="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FComments.svg?alt=media&token=f3c7fbad-dcaa-4c45-9d26-9bec9703ad57"
                                alt="Instagram Logo"
                            />
                            <p className="post__footer__text">{requiring_review} comments requiring review</p>
                        </div>

                    </div>
                </div>
            </div>
            {/* </div> */}
        </NavLink>
    );
}

export default Post;