import React from 'react'
import './deleteDialog.css'
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


export default function DeleteDialog({ toggleDialog, invokeLambda, comment_id }) {
    console.log(comment_id)
    return (
        <div className="deleteDialog__outerContainer">
            <div className="deleteDialog__innerContainer">
                <div className="deleteDialog__textContainer">
                    <p className="deleteDialog__text1">Are you sure want to delete this comment?</p>
                    <p className="deleteDialog__text2" >This action cannot be reversed</p>
                </div>
                <div className='deleteDialog_buttonContainer'>
                    <div className="deleteDialog__deleteButton" onClick={() => invokeLambda("delete", comment_id)}>
                        <DeleteIcon sx={{ margin: "auto 0px auto auto", color: "white", fontSize: 40 }} />
                        <p className="deleteDialog__buttonText">Yes, delete</p>
                    </div>
                    <div className="deleteDialog__ignoreButton" onClick={toggleDialog}>
                        <ArrowBackIcon sx={{ margin: "auto 0px auto auto", color: "white", fontSize: 40 }} />
                        <p className="deleteDialog__buttonText">No, return</p>
                    </div>
                </div>

            </div>
        </div>
    )
}
