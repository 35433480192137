import React, { useState, useEffect } from 'react'
import { Grid } from 'react-loading-icons'
import "./login.css";

import { CognitoUser, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';

import UserPool from "./UserPool"

import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { DynamoDBDocumentClient, PutCommand } from "@aws-sdk/lib-dynamodb";
import { SettingsApplicationsRounded } from '@mui/icons-material';

const accessKeyId = process.env.REACT_APP_DYNAMO_KEY_ID;
const secretAccessKey = process.env.REACT_APP_DYNAMO_SECRET_KEY;

const client = new DynamoDBClient({
    region: "us-east-1",
    credentials: {
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey
    }
});
const docClient = DynamoDBDocumentClient.from(client);



function Login() {
    const [loaded, setLoaded] = useState(true)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [passwordWarning, setPasswordWarning] = useState(false)
    const [mode, setMode] = useState("login")
    const [validated, setValidated] = useState(false)

    const cognitoLogin = () => {
        const user = new CognitoUser({
            Username: email,
            Pool: UserPool
        })


        const authDetails = new AuthenticationDetails({
            Username: email,
            Password: password
        })

        user.authenticateUser(authDetails, {
            onSuccess: (data) => {
                console.log("onSuccess: ", data)
                window.location.reload();
            },
            onFailure: (err) => {
                console.log("onFailure: ", err)
            }
        })
    }

    async function connectInstagram() {
        if (!validated) return

        window.FB.login(function (response) {
            // handle the response 
            console.log(response)
            if (response.status === "connected") {
                const uid = response.authResponse.userID
                const access_token = response.authResponse.accessToken
                writeToDynamo(uid, access_token)
                cognitoSignup(uid)
                setLoaded(false)

            }
        }, { scope: 'instagram_basic,pages_show_list,pages_read_engagement,instagram_manage_comments' });
        // { scope: 'instagram_basic,instagram_manage_insights,pages_show_list,pages_read_engagement,instagram_manage_comments, ads_management, business_management' }
    }

    async function writeToDynamo(uid, access_token) {
        const command = new PutCommand({
            TableName: 'sentiment-users',
            Item: {
                pk: `USER#${uid}`,
                sk: `#PROFILE#${uid}`,
                access_token: access_token,
                posts: {},
                requiring_review: 0,
                sentiment: 0,
                trend: 0
            },
        });

        const response = await docClient.send(command);
        console.log(response);

        return response;
    }

    async function cognitoSignup(uid) {
        const attributeList = []

        attributeList.push(
            new CognitoUserAttribute({ Name: "given_name", Value: firstName })
        )
        attributeList.push(
            new CognitoUserAttribute({ Name: "family_name", Value: lastName })
        )
        attributeList.push(
            new CognitoUserAttribute({ Name: "custom:uid", Value: uid })
        )



        UserPool.signUp(email, password, attributeList, null, (err, data) => {
            if (err) {
                console.log(err)
            }
            console.log(data)
            if (data.userConfirmed) {
                setMode("login")
                setLoaded(true)
            }
        })
    }


    const verifyPassword = (pass) => {
        setPassword(pass)
    }

    useEffect(() => {
        if (new RegExp(/^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/).test(password)) {
            setValidated(true)
        } else if (password) {
            setValidated(false)
        }
    }, [password])

    const handleKeyDown = (e) => {
        if (validated && e.code === "Enter") {
            cognitoLogin()
        }
    };

    const handleKeyDownSignUp = (e) => {
        if (validated && e.code === "Enter") {
            connectInstagram()
        }
    };


    return (

        <div className="login__container">
            <div className="homepage__logoContainer">
                <img className="homepage__logo" src="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FSecondary_Logo_White.svg.svg?alt=media&token=eba71d77-ab3d-4be9-94c2-bcd9ab6dbd09&_gl=1*1mu1zle*_ga*ODA3NjkwNDg5LjE2OTQxNTg1ODY.*_ga_CW55HF8NVT*MTY5NzA5NDE1Ni4xMS4xLjE2OTcwOTU2OTQuNS4wLjA." />
                <p className="homepage__text1">Putting AI in your hands</p>
                <p className="homepage__text2">Manage your social media presence using NLP</p>
            </div>

            <div className="login__loginFormContainer">
                {mode == "login"
                    ?
                    <>
                        <p className="login__welcomeText">WELCOME BACK</p>
                        <p className="login__loginText">Login to your account</p>
                        <div className="login__inputContainer">
                            <label className="login__inputLabel" htmlFor='Email'>Email</label>
                            <input
                                className="login__input"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                            >
                            </input>
                        </div>
                        <div className="login__inputContainer">
                            <label className="login__inputLabel" htmlFor='Password'>Password</label>
                            <input
                                className="login__input"
                                value={password}
                                type="password"
                                onChange={(event) => setPassword(event.target.value)}
                                onKeyDown={handleKeyDown}
                            >
                            </input>
                        </div>
                        <div className="login__inputContainer">
                            <p className="login__forgotPassword">Forgot password?</p>
                        </div>
                        <div className="login__inputContainer">
                            <button className={`login__loginButton${email && password ? "active" : ""}`} onClick={cognitoLogin}>
                                CONTINUE
                            </button>
                        </div>
                        <div className="login__inputContainer">
                            <p className="login__newUser">New user? <span onClick={() => setMode("signup")}>SIGN UP HERE</span></p>
                        </div></>
                    :
                    <>
                        <p className="login__welcomeText">LET'S GET STARTED</p>
                        <p className="login__loginText">Create an account</p>
                        <div className="login__inputContainer">
                            <label className="login__inputLabel" htmlFor='FirstName'>First name</label>
                            <input
                                className="login__input"
                                value={firstName}
                                onChange={(event) => setFirstName(event.target.value)}
                            >
                            </input>
                        </div>
                        <div className="login__inputContainer">
                            <label className="login__inputLabel" htmlFor='LastName'>Last name</label>
                            <input
                                className="login__input"
                                value={lastName}
                                onChange={(event) => setLastName(event.target.value)}
                            >
                            </input>
                        </div>
                        <div className="login__inputContainer">
                            <label className="login__inputLabel" htmlFor='Email'>Email</label>
                            <input
                                className="login__input"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                            >
                            </input>
                        </div>
                        <div className="login__inputContainer">
                            <label className="login__inputLabel" htmlFor='Password'>Password</label>
                            <input
                                className="login__input"
                                value={password}
                                type="password"
                                onChange={(event) => verifyPassword(event.target.value)}
                                onKeyDown={handleKeyDownSignUp}
                            >
                            </input>
                        </div>

                        {!loaded
                            ?
                            <div className="signup_gridContainer">
                                <div className="signup_gridContainerInner">
                                    <Grid height="40" width="40" fill="black" stroke="#EFF3FD" strokeOpacity={1} speed={.5} />
                                </div>
                            </div>
                            :
                            <>
                                <div className="login__inputContainer">

                                    <button className={`login__loginButton${firstName && lastName && email && validated ? "active" : ""}`} onClick={connectInstagram}>
                                        SIGN UP
                                    </button>
                                    {password && !validated
                                        ? <>
                                            <p className='signup__passwordWarning' >
                                                Passwords must contain at least 8 characters, 1 number, 1 special character, 1 uppercase letter and 1 lowercase letter
                                            </p>
                                        </>
                                        : null}
                                </div>
                                <div className="login__inputContainer">
                                    <p className="login__newUser">Aleady signed up? <span onClick={() => setMode("login")}>SIGN IN</span></p>
                                </div>
                            </>}

                    </>
                }

            </div>
        </div>

    );
}

export default Login;