import React, { useEffect, useState, useContext } from "react";
import './newsfeed.css'
import Sidenav from '../navigation/Sidenav'
import Header from '../header/header'
import Select, { StylesConfig } from 'react-select'

import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { DynamoDBDocumentClient, ScanCommand } from "@aws-sdk/lib-dynamodb";

const accessKeyId = process.env.REACT_APP_DYNAMO_KEY_ID;
const secretAccessKey = process.env.REACT_APP_DYNAMO_SECRET_KEY;

const client = new DynamoDBClient({
    region: "us-east-1",
    credentials: {
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey
    }
});
const docClient = DynamoDBDocumentClient.from(client);

const options = [
    { value: 'hsbc', label: 'hsbc', color: "black" },
    { value: 'shell', label: 'shell', color: "black" },
    { value: 'coca cola', label: 'coca cola', color: "black" },
    { value: 'tfl', label: 'tfl', color: "black" },
    { value: 'john lewis', label: 'john lewis', color: "black" },
    { value: 'm&s', label: 'm&s', color: "black" },
]


export default function NewsFeed({ loggedIn }) {
    const [company, setCompany] = useState("hsbc")
    const [articles, setArticles] = useState([])

    console.log({ loggedIn })


    useEffect(() => {

        async function main() {

            const command = new ScanCommand({
                TableName: "sentiment-company-data",
                ExpressionAttributeNames: { "#company": "company" },
                ExpressionAttributeValues: {
                    ':company': company,
                },
                FilterExpression: "#company = :company"

            });


            const response = await docClient.send(command);
            console.log(response)

            // Puts day of month in 2-digit format e.g 7 ==> 07
            let date = ""
            if (String(new Date().getDate()).length == 1) {
                date = "0" + new Date().getDate()
            } else {
                date = new Date().getDate()
            }

            // Retrieves today's entry
            console.log(company + new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + date)
            response.Items.map(entry => {
                if (entry.sk.includes(company + new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + date)) {
                    console.log(entry)
                }
            })



            // var response = await fetch("https://www.googleapis.com/customsearch/v1?key=" + "KEY HERE" + "&cx=3088cd62633e649bd" + "&q=hsbc" + "&sort=date")
            //     .then(function (response) {
            //         return response.json()
            //     }).then(function (data) {
            //         let newArray = []
            //         data.items.map(article => {
            //             newArray.push({
            //                 "website": article["displayLink"],
            //                 "title": article["title"],
            //                 "snippet": article["snippet"],
            //                 "publishedAt": article["pagemap"]["metatags"][0]["article:published_time"] || "",
            //                 "modifiedAt": article["pagemap"]["metatags"][0]["article:modified_time"] || ""
            //             })
            //         })
            //         console.log(newArray)
            //         setArticles(newArray)
            //     })
            //     .catch(error => console.error(error))
        }
        main()

    }, [company])

    return (
        <div className="homepage">
            <div className="homepage__navWraper">
                <Sidenav />
            </div>
            <div className="homepage__timeline">
                <div className="timeline">
                    <Header />
                    <div className="review__dropdownContainer">
                        <Select
                            defaultValue={options[0]}
                            onChange={(e) => setCompany(e.value)}
                            options={options}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    width: "200px",
                                    fontSize: "12px",
                                    color: "#374BD2",
                                    borderColor: "#374BD2",
                                }),
                                option: (baseStyles, { data, isDisabled, isFocused, isSelected }) => {
                                    return {
                                        ...baseStyles,
                                        backgroundColor: "white",
                                        color: "black",
                                        width: "200px",
                                        fontSize: "12px",
                                    }

                                },
                            }}


                        />

                    </div>
                    <div className="review__outerContainer">
                        <div className="review__postContainer">
                            <div className="newsFeed_articleOuterContainer">
                                {articles.length >= 0 && articles.map(article => {
                                    return (
                                        <div className="newsFeed__articleInnerContainer">
                                            <p className="newsFeed__articleTextTitle">{article.title}</p>
                                            <p className="newsFeed__articleTextPublisher">{article.website}</p>
                                            <p className="newsFeed__articleText">{article.snippet}</p>
                                            {/* <p className="newsFeed__articleText">{article.publishedAt}</p>
                                            <p className="newsFeed__articleText">{article.modifiedAt}</p> */}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div className="review__reviewContainer">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
