import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom"
import { AppContext } from '../../context';
import './review.css'
import Sidenav from '../navigation/Sidenav'
import Header from '../header/header'
import Select, { StylesConfig } from 'react-select'
import { CircularProgressbar } from 'react-circular-progressbar';
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';
import DeleteDialog from "./deleteDialog";
import Spinner from "../spinner/spinner"


import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { DynamoDBDocumentClient, QueryCommand, GetCommand } from "@aws-sdk/lib-dynamodb";
import { LambdaClient, InvokeCommand } from "@aws-sdk/client-lambda"; // ES Modules import
const accessKeyId = process.env.REACT_APP_DYNAMO_KEY_ID;
const secretAccessKey = process.env.REACT_APP_DYNAMO_SECRET_KEY;

const client = new DynamoDBClient({
    region: "us-east-1",
    credentials: {
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey
    }
});
const docClient = DynamoDBDocumentClient.from(client);

const lambdaClient = new LambdaClient({
    region: "us-east-1",
    credentials: {
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey
    }
});


export default function Review({ loggedIn }) {

    const [loading, setLoading] = useState(false)
    const [comments, setComments] = useState([])
    const [commentOptions, setCommentOptions] = useState("unresolved_comments")
    const [obj, setObj] = useState(null)
    const [showDialog, setShowDialog] = useState({ display: false, comment_id: "" })
    const [commentObj, setCommentObj] = useState({})
    // const [comment_id, setComment_id] = useState("")

    const params = useParams();
    const post = params.post
    const navigate = useNavigate()
    const context = useContext(AppContext)

    const options = [
        { value: 'timestamp', label: 'Recently posted', color: "black" },
        { value: 'unresolved_count', label: 'Requiring review', color: "black" },
        { value: 'sentiment_asc', label: 'Sentiment ascending', color: "black" },
        { value: 'sentiment_desc', label: 'Sentiment descending', color: "black" },
    ]

    const postOptions = [
        { value: 'unresolved_comments', label: 'Requiring review', color: "black", cursor: "pointer" },
        { value: 'resolved_comments', label: 'Positive/neutral comments', color: "black" },
        { value: 'ignored_comments', label: 'Previously ignored comments', color: "black" },
    ]



    useEffect(() => {
        if (comments.length) return
        if (!context.state.response?.posts) return;
        if (!context.state.response.posts[post]) {
            console.log("Post not found")
            navigate("/")
        }

        async function main() {
            const uid = context.state.response.pk.slice(5)
            const command = new QueryCommand({
                TableName: "sentiment",
                KeyConditionExpression:
                    "pk = :a",
                ExpressionAttributeValues: {
                    ":a": `USER#${uid}#POST#${post}`,
                },
            });

            const response = await docClient.send(command);

            const postObj = context.state.response.posts[post]
            setObj(postObj)

            let newArray = []

            let commentObject = response.Items
            setCommentObj(response.Items)

            console.log("postObj", postObj)
            console.log("commentObj", commentObject)

            postObj[commentOptions].map(comment => {
                console.log(comment)
                commentObject.map(c => {
                    if (c.comment_id == comment) {
                        newArray.push(c)
                    }
                })
            })

            newArray.sort(function (a, b) {
                return new Date(b.timestamp) - new Date(a.timestamp)
            })

            console.log(newArray)

            setComments(newArray)
        }
        main()

    }, [context.state.response?.posts])

    if (!obj) return null

    function sortComments(sortKey) {

        //     console.log(sortKey)
        //     console.log(posts)

        //     const newState = [...posts]

        //     if (sortKey == "unresolved_count") {
        //         newState.sort(function (a, b) {
        //             return b[sortKey] - a[sortKey]

        //         })
        //     } else if (sortKey == "timestamp") {
        //         newState.sort(function (a, b) {
        //             return new Date(b.timestamp) - new Date(a.timestamp)
        //         })

        //     }

        //     console.log(posts)


        //     setPosts(newState)

    }

    async function invokeLambda(mode, comment_id) {
        setLoading(true)
        const payload = JSON.stringify({
            "comment_type": commentOptions,
            "mode": mode,
            "pk": context.state.response.pk,
            "sk": context.state.response.sk,
            "access_token": context.state.response.access_token,
            "comment_id": comment_id,
            "post_id": post,
            "posts": context.state.response.posts
        })
        console.log(payload)

        if (mode == "delete") {
            setShowDialog({ ...showDialog, display: false })
        }

        const input = { // InvocationRequest
            FunctionName: "delete-comment", // required
            Payload: payload
        };
        const command = new InvokeCommand(input);
        const response1 = await lambdaClient.send(command);
        console.log("lambda response", response1)

        const command1 = new GetCommand({
            TableName: "sentiment-users",
            Key: {
                pk: context.state.response.pk,
                sk: context.state.response.sk
            }
        });

        const res = await docClient.send(command1);
        const response = res.Item
        console.log(response)

        // const newState = { ...context.state }
        // newState.response = response

        context.setState(state => ({
            ...state,
            response
        }))

        console.log(context, { response })

        // setComments(context.state.response.postOptions)
        console.log(commentOptions)
        listFilteredPosts(commentOptions, response)
    }

    function getTimeElapsed(timestamp) {
        // const timeElapsed = (Math.floor((Date.now() - new Date(timestamp)) / 1000 / 60 / 60 / 24))
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const day = new Date(timestamp).getDate()
        const month = monthNames[new Date(timestamp).getMonth()]
        return (<p className="review__commentDate">
            {day} {month}
        </p>)
    }

    function listFilteredPosts(mode, response) {
        const { posts } = response || context.state.response
        const postObj = posts[post]
        console.log(postObj)
        const newCommentObj = postObj[mode]
        console.log(newCommentObj)

        const newArray = []
        console.log("commentOBj", commentObj)

        newCommentObj.map(comment => {
            commentObj.map(c => {
                if (c.comment_id == comment) {
                    newArray.push(c)
                }
            })
        })

        newArray.sort(function (a, b) {
            return new Date(b.timestamp) - new Date(a.timestamp)
        })

        setComments(newArray)
        setLoading(false)
    }

    function toggleCommentOptions(mode) {
        setCommentOptions(mode)
        listFilteredPosts(mode)
    }

    function getEmptyCommentsDescriptor() {
        if (commentOptions == "unresolved_comments") {
            return (
                <p className="review__noReviewText">No comments require review for this post</p>
            )
        } else if (commentOptions == "resolved_comments") {
            return (
                <p className="review__noReviewText">No positive/neutral comments for this post</p>
            )
        } else if (commentOptions == "ignored_comments") {
            return (
                <p className="review__noReviewText">No previously ignored comments for this post</p>
            )
        }
    }

    function toggleDialog(c) {
        console.log(c)
        setShowDialog({ display: showDialog.display ? false : true, comment_id: c })
    }

    return (
        <div className="homepage">
            {showDialog.display
                ?
                <DeleteDialog toggleDialog={toggleDialog} invokeLambda={invokeLambda} comment_id={showDialog.comment_id} />
                : null
            }
            {loading
                ?
                <Spinner />
                : null
            }
            <div className="homepage__navWraper">
                <Sidenav />
            </div>
            <div className="homepage__timeline">
                <div className="timeline">
                    <Header />
                    <div className="review__outerContainer">
                        <div className="review__postContainer">
                            {obj
                                ?
                                <div className="review__postContainerInner">
                                    {/* <div className="review__sentimentScoreContainer">
                                        <div className="review__sentimentProgressContainer">
                                            <CircularProgressbar value={obj.score} text={obj.score} styles={{
                                                text: {
                                                    fontWeight: "bold",
                                                    fontSize: "30px"
                                                },
                                            }} />
                                        </div>
                                        <p className="review__sentimentScore">Sentiment score</p>
                                    </div> */}
                                    <div className="review__imgContainer">
                                        <img className="review__img" src={obj.media_url} alt="Post Image" />
                                    </div>
                                    <p className="review__postImgLikes">{obj.likes} likes</p>
                                    <p className="review__postImgCaption"><span>{obj.username}</span>{" " + obj.caption}</p>



                                </div>
                                :
                                <div>Loading</div>
                            }


                        </div>
                        <div className="review__reviewContainer">
                            <div className="review__requiringOuterContainer">

                                <MarkChatUnreadIcon sx={{ margin: "auto 0px auto auto", color: "#0fd697", fontSize: 40 }} />


                                <p className="review__unresolved__count">{obj.unresolved_count + " "}</p>

                                <div className="review__requiringContainer">
                                    <p className="review__requiringText">REQUIRING REVIEW</p>
                                </div>
                            </div>
                            <div className="review__sortContainer__outer">
                                {/* <div className="timeline__sortContainer__inner">
                                    <p className="timeline_sortContainer__text">
                                        Sort by
                                    </p>
                                    <Select
                                        defaultValue={options[0]}
                                        onChange={(e) => sortComments(e.value)}
                                        options={options}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                width: "200px",
                                                fontSize: "12px",
                                                color: "#374BD2",
                                                borderColor: "black",
                                            }),
                                            option: (baseStyles, { data, isDisabled, isFocused, isSelected }) => {
                                                return {
                                                    ...baseStyles,
                                                    backgroundColor: "white",
                                                    color: "black",
                                                    width: "200px",
                                                    fontSize: "12px",
                                                }

                                            },
                                        }}
                                    />
                                </div> */}


                                <div className="timeline__sortContainer__inner">
                                    <p className="timeline_sortContainer__text">
                                        Show me comments
                                    </p>
                                    <Select
                                        defaultValue={postOptions[0]}
                                        onChange={(e) => toggleCommentOptions(e.value)}
                                        options={postOptions}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                width: "200px",
                                                fontSize: "12px",
                                                color: "#374BD2",
                                                borderColor: "black",
                                            }),
                                            option: (baseStyles, { data, isDisabled, isFocused, isSelected }) => {
                                                return {
                                                    ...baseStyles,
                                                    backgroundColor: "white",
                                                    color: "black",
                                                    width: "200px",
                                                    fontSize: "12px",
                                                }

                                            },
                                        }}
                                    />

                                </div>

                            </div>

                            {comments.length == 0
                                ?
                                <div className="review__commentOptionsContainer">
                                    {getEmptyCommentsDescriptor()}
                                </div>
                                :
                                <div className="review__innerReviewContainer">
                                    {comments && comments.map((comment) => {
                                        return (
                                            <div key={comment.comment_id} className="review__commentContainer">
                                                <p className="review__commentText"><span className="review__commentTextBold">{comment.comment_username + " "}</span>{comment.inputs}</p>
                                                <div>{getTimeElapsed(comment.timestamp)}</div>
                                                <div className="review__bottomContainer">
                                                    {/* <div className="review__sentimentContainer">
                                                        <div className="review__commentProgressContainer">
                                                            <CircularProgressbar value={comment.score || 40} />
                                                        </div>
                                                        <p className="review__commentSentimentText">Sentiment score: {Math.round(comment.score * 100) || 40}</p>
                                                    </div> */}
                                                    <div className="review__buttonContainerOuter">
                                                        {commentOptions == "unresolved_comments"
                                                            ?
                                                            <div className="review__ignoreButton" onClick={() => invokeLambda("ignore", comment.comment_id)}>
                                                                Ignore
                                                            </div> : null
                                                        }

                                                        <div className="review__deleteButton" onClick={() => toggleDialog(comment.comment_id)}>
                                                            Delete
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>


                            }
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
