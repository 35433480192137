import React from 'react'
import './settings.css'
import Sidenav from '../navigation/Sidenav'


export default function settings() {
    return (
        <div className="settings">
            <Sidenav />
            <div className="settings__header">
                <p className="settings__header__text">Settings</p>
            </div>
        </div>
    )
}
