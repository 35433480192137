import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom"
import "./verify.css";



import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';

import UserPool from "./UserPool"



function Verify() {
    const [state, setState] = useState({ validated: false })

    const params = useParams();

    useEffect(() => {
        if (!isNaN(Number(state.val1)) &&
            !isNaN(Number(state.val2)) &&
            !isNaN(Number(state.val3)) &&
            !isNaN(Number(state.val4)) &&
            !isNaN(Number(state.val5)) &&
            !isNaN(Number(state.val6))
        ) {
            setState(prev => ({
                ...prev,
                validated: true
            }
            ))
        }
        console.log("hi")
    }, [state.val6])


    const verify = () => {
        const user = new CognitoUser({
            Username: params.email,
            Pool: UserPool
        })

        const code = "" + state.val1.toString() + state.val2.toString() + state.val3.toString() + state.val4.toString() + state.val5.toString() + state.val6.toString()

        console.log(code)

        user.confirmRegistration(code, false, (error, result) => {
            if (error) {
                console.log(error)
            } else {
                console.log(result)
            }
        })

    }


    const handleNextInput = (e, desc, num) => {
        if (!e) return
        setState(prev => ({
            ...prev,
            [desc]: e
        }
        ))
        if (num == 6) {
            document.getElementById(`val6`).blur()
            return
        }
        document.getElementById(`val${num + 1}`).focus()
    }


    return (
        <div className="verify__outerContainer">
            <div className="verify__container">
                <div className="homepage__logoContainer">
                    <img className="homepage__logo" src="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FSecondary_Logo_White.svg.svg?alt=media&token=eba71d77-ab3d-4be9-94c2-bcd9ab6dbd09&_gl=1*1mu1zle*_ga*ODA3NjkwNDg5LjE2OTQxNTg1ODY.*_ga_CW55HF8NVT*MTY5NzA5NDE1Ni4xMS4xLjE2OTcwOTU2OTQuNS4wLjA." />
                    <p className="homepage__text1">Putting AI in your hands</p>
                    <p className="homepage__text2">Manage your social media presence using NLP</p>
                </div>
                <div className="verify__rightContainer">
                    <div className="login__loginFormContainer">
                        <p className="login__loginText">Verify your account</p>
                        <div className="login__inputContainer">
                            <label className="login__inputLabel" htmlFor='Email'>Enter the 6-digit code sent to your email</label>
                            <div className="verify__codeOuter">
                                <input
                                    id="val1"
                                    className="verify__input"
                                    type="number"
                                    min="0"
                                    max="9"
                                    // onKeyUp={(e) => handleNextInput(e.target.value, 1)}
                                    value={state.val1}
                                    onChange={(event) => handleNextInput(event.target.value, "val1", 1)}
                                >
                                </input>
                                <input
                                    id="val2"
                                    // onKeyUp={(e) => handleNextInput(e.target.value, 2)}
                                    className="verify__input"
                                    type="number"
                                    min="0"
                                    max="9"
                                    value={state.val2}
                                    onChange={(event) => handleNextInput(event.target.value, "val2", 2)}
                                >
                                </input>
                                <input
                                    id="val3"
                                    // onKeyUp={(e) => handleNextInput(e.target.value, 3)}
                                    className="verify__input"
                                    type="number"
                                    min="0"
                                    max="9"
                                    value={state.val3}
                                    onChange={(event) => handleNextInput(event.target.value, "val3", 3)}
                                >
                                </input>
                                <input
                                    id="val4"
                                    // onKeyUp={(e) => handleNextInput(e.target.value, 4)}
                                    className="verify__input"
                                    type="number"
                                    min="0"
                                    max="9"
                                    value={state.val4}
                                    onChange={(event) => handleNextInput(event.target.value, "val4", 4)}
                                >
                                </input>
                                <input
                                    id="val5"
                                    // onKeyUp={(e) => handleNextInput(e.target.value, 5)}
                                    className="verify__input"
                                    type="number"
                                    min="0"
                                    max="9"
                                    value={state.val5}
                                    onChange={(event) => handleNextInput(event.target.value, "val5", 5)}
                                >
                                </input>
                                <input
                                    id="val6"
                                    className="verify__input"
                                    type="number"
                                    min="0"
                                    max="9"
                                    value={state.val6}
                                    onChange={(event) => handleNextInput(event.target.value, "val6", 6)}
                                >
                                </input>
                            </div>

                        </div>
                        <div className="login__inputContainer">
                            <button className={`login__loginButton${state.validated ? "active" : ""}`} onClick={verify}>
                                CONTINUE
                            </button>
                        </div>
                    </div>
                </div>

                {/* <label style={{ color: "black" }} htmlFor='Verification code'>Email</label>
                <input
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                >
                </input>
                <label style={{ color: "black" }} htmlFor='Verification code'>Verification code</label>
                <input
                    value={code}
                    onChange={(event) => setCode(event.target.value)}
                >
                </input>
                <button onClick={verify}>
                    Verify
                </button> */}
            </div>
        </div>

    );
}

export default Verify;