import React, { useEffect, useState, useContext } from "react";
import moment from 'moment';
import Post from "./Post";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Select, { StylesConfig } from 'react-select'
import RefreshIcon from '@mui/icons-material/Refresh';
import { Grid } from 'react-loading-icons'
import Header from '../header/header'

import { AppContext } from '../../context';
import "./Timeline.css";


import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { DynamoDBDocumentClient, GetCommand } from "@aws-sdk/lib-dynamodb";
import { SFNClient, StartExecutionCommand } from "@aws-sdk/client-sfn"; // ES Modules import


const accessKeyId = process.env.REACT_APP_DYNAMO_KEY_ID;
const secretAccessKey = process.env.REACT_APP_DYNAMO_SECRET_KEY;

const client = new DynamoDBClient({
    region: "us-east-1",
    credentials: {
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey
    }
});
const docClient = DynamoDBDocumentClient.from(client);

const options = [
    { value: 'timestamp', label: 'Recently posted', color: "black" },
    { value: 'unresolved_count', label: 'Requiring review', color: "black" },
    // { value: 'sentiment_asc', label: 'Sentiment ascending', color: "black" },
    // { value: 'sentiment_desc', label: 'Sentiment descending', color: "black" },
]

function Timeline() {
    const [loading, setLoading] = useState(true)
    const [parsedAt, setParsedAt] = useState("")
    const [parsedAtRaw, setParsedAtRaw] = useState("")
    const [posts, setPosts] = useState([])
    const [score, setScore] = useState(null)
    const [trend, setTrend] = useState(null)
    const [trendDescriptor, setTrendDescriptor] = useState(null)
    const [reviewable, setReviewable] = useState(null)
    const context = useContext(AppContext)

    useEffect(() => {
        async function main() {
            //2
            // if (!context.state?.response) return;

            if (!context.state?.session) return;

            //1
            // const uid = context.state.response.authResponse.userID
            const uid = context.state.session.idToken.payload["custom:uid"]


            const command = new GetCommand({
                TableName: "sentiment-users",
                Key: {
                    pk: `USER#${uid}`,
                    sk: `#PROFILE#${uid}`
                }
            });

            const response = await docClient.send(command);

            let postObject = response.Item.posts
            let newArray = []

            Object.keys(postObject).map(post => {
                postObject[post]["post_number"] = post
                newArray.push(
                    postObject[post]
                )
            })

            newArray.sort(function (a, b) {
                return new Date(b.timestamp) - new Date(a.timestamp)

            })

            var mydate = moment(response.Item.parsed_at).format('MMMM DD h:mm:ss a')
            console.log(mydate)
            setParsedAtRaw(response.Item.parsed_at)
            setParsedAt(mydate)

            setPosts(newArray)
            setScore(response.Item.sentiment)


            var trendDescriptor = ""
            if (trend >= 0) {
                trendDescriptor = "improving"
            } else if (trend < 0) {
                trendDescriptor = "declining"
            }

            setTrendDescriptor(trendDescriptor)
            setTrend(response.Item.trend)
            setReviewable(response.Item.requiring_review)
            setLoading(false)

            console.log(context)

        }
        main()
        //3
        // }, [context.state?.response])
    }, [context.state?.session])

    function sortPosts(sortKey) {

        console.log(sortKey)
        console.log(posts)

        const newState = [...posts]

        if (sortKey == "unresolved_count") {
            newState.sort(function (a, b) {
                return b[sortKey] - a[sortKey]

            })
        } else if (sortKey == "timestamp") {
            newState.sort(function (a, b) {
                return new Date(b.timestamp) - new Date(a.timestamp)
            })

        }

        console.log(posts)


        setPosts(newState)

    }

    function getTrendingIcon() {

        switch (trendDescriptor) {
            case "improving":
                return (<div className="uparrow" />)
            case "declining":
                return (<div className="downarrow" />)
            default:
                return
        }
    }

    async function refreshFeed() {

        const accessKeyId = process.env.REACT_APP_DYNAMO_KEY_ID;
        const secretAccessKey = process.env.REACT_APP_DYNAMO_SECRET_KEY;
        const arn = process.env.REACT_APP_SENTIMENT_SINGLE_ARN

        const client = new SFNClient({
            region: "us-east-1",
            credentials: {
                accessKeyId: accessKeyId,
                secretAccessKey: secretAccessKey
            }
        });

        const uid = context.state.session.idToken.payload["custom:uid"]
        const id = `{\"id\":\"${uid}\"}`
        const input = { // StartExecutionInput
            stateMachineArn: arn, // required
            input: id,
        };
        const command = new StartExecutionCommand(input);
        const response = await client.send(command);
        console.log(response)

        function oldFashion() {
            for (let i = 0; i < 10; i++) {
                schedule(i, i);
            }
            schedule("Finish", 10);
        }
        oldFashion();

        function schedule(msg, i) {
            setTimeout(() => {
                console.log(msg);
                callDynamo(i + 1)
            }, i * 5000);
        }

        setLoading(true)
    }

    async function callDynamo(attempt) {
        //3 Replaced FB retrieval
        // const uid = context.state.response.authResponse.userID
        // const access_token = context.state.response.authResponse.accessToken
        const uid = context.state.session.idToken.payload["custom:uid"]

        const command = new GetCommand({
            TableName: "sentiment-users",
            Key: {
                pk: `USER#${uid}`,
                sk: `#PROFILE#${uid}`
            }
        });

        const res = await docClient.send(command);
        let response = res.Item
        console.log("attempt" + attempt, response)
        if (response.parsed_at != parsedAtRaw) {
            window.location.reload()
        }
    }


    return (


        <div className="timeline">
            <Header />
            {/* <div className="timeline__dash">
                <div className="timeline__dash__box_left">
                    <div className="timeline__dash__container">
                        <div className="timeline__dash__scoreContainer">
                            <CircularProgressbar value={score} text={score} />
                        </div>
                        <p className="timeline__dash__scoreText">Sentiment score</p>
                    </div>
                </div>
                <div className="timeline__dash__box_center">
                    <div className="timeline__dash__container">
                        <div className={`timeline__dash__trendingContainer${trendDescriptor}`}>
                            <p className="timeline__dash__trendingText">{trend}%</p>
                            {getTrendingIcon()}
                        </div>
                        <p className="timeline__dash__scoreText">Sentiment trend</p>
                    </div>
                </div>
                <div className="timeline__dash__box_right">
                    <p className="timeline__dash__reviewableText">{reviewable}</p>
                    <div className="timeline__dash__reviewContainer">
                        <p className="timeline__dash__requireReviewText">Comments requiring review</p>
                        <p className="timeline__dash__requireReviewLink">Review comments</p>
                    </div>

                </div>
            </div> */}


            <div className="timeline__posts">

                {

                    posts.length > 0
                        ?
                        <div className="timeline__gallery__outer">
                            <div className="gallery">
                                <div className="timeline__sortContainer__outer">
                                    <div className="timeline__sortContainer__inner">
                                        <p className="timeline_sortContainer__text">
                                            Sort by
                                        </p>

                                        <Select
                                            defaultValue={options[0]}
                                            onChange={(e) => sortPosts(e.value)}
                                            options={options}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    width: "200px",
                                                    fontSize: "12px",
                                                    color: "#374BD2",
                                                    borderColor: "black",
                                                }),
                                                option: (baseStyles, { data, isDisabled, isFocused, isSelected }) => {
                                                    return {
                                                        ...baseStyles,
                                                        backgroundColor: "white",
                                                        color: "black",
                                                        width: "200px",
                                                        fontSize: "12px",
                                                    }

                                                },
                                            }}


                                        />

                                    </div>
                                    {/* <div className="timeline__sortContainer__inner">
                                <p className="timeline_sortContainer__text">
                                    Filter
                                </p>
                                <div className="timeline__sortContainer__sortContainer">

                                </div>
                            </div> */}
                                </div>
                                <div>
                                    {/* <div className="timeline__parsedAtOuterContainer">
                                        <div className="timeline__parsedAtContainer">
                                            <p className="timeline__parsedAt">Last refreshed at: </p>
                                            <p className="timeline__parsedAtTime">{parsedAt}</p>
                                        </div>
                                        <div className="timeline__refreshIcon__parsedAt" onClick={refreshFeed}>
                                            <RefreshIcon sx={{ color: "grey", fontSize: 40 }} />
                                        </div>
                                    </div> */}
                                </div>

                                <div>

                                </div>


                                {posts && posts.map((post) => (
                                    <Post
                                        key={post.media_url}
                                        user="erw"
                                        postImage={post.media_url}
                                        sentiment={post.sentiment || "efef"}
                                        requiring_review={post.unresolved_count}
                                        likes={post.likes || 0}
                                        timestamp={post.timestamp}
                                        score={post.score}
                                        media_id={post.post_number}
                                    />
                                ))}
                            </div>
                        </div>
                        :
                        <div className="timeline__emptyPosts">
                            {!context.state?.response
                                ?
                                <>
                                    <p className="timeline__emptyPosts__text">You don't have any posts</p>

                                    {parsedAt
                                        ?
                                        <p className="timeline__lastRefreshed">Last refreshed at: {parsedAt}</p>
                                        :
                                        <p className="timeline__lastRefreshed">Refresh to retrieve your posts</p>
                                    }

                                </>
                                :
                                <>
                                    {/* Empty p elements below maintain centred div for the loading spinner */}

                                    <p className="timeline__emptyPosts__text">You don't have any posts</p>
                                    <p className="timeline__lastRefreshed">Refresh to retrieve your posts</p>
                                </>}
                            {loading
                                ?
                                <div className="timeline__gridContainer">
                                    <Grid height="40" width="40" fill="black" stroke="#EFF3FD" strokeOpacity={1} speed={.5} />
                                </div>
                                :
                                <div className="timeline__refreshIcon__container" onClick={refreshFeed}>

                                    <RefreshIcon sx={{ color: "grey", fontSize: 55 }} />
                                </div>
                            }

                        </div>

                }


            </div>
        </div >

    );
}

export default Timeline