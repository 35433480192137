import { useEffect, useState, useContext } from 'react';
import './App.css';
import { initFacebookSdk } from './helpers/helpers'
import { AppContext } from './context';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom'

import Homepage from './Homepage';
import Review from './components/review/review'
import NewsFeed from './components/newsfeed/newsfeed';
import Settings from './components/settings/settings';
import Verify from './verify'
import PrivateRoute from './PrivateRoute';

import { FacebookProvider, LoginButton } from 'react-facebook';
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { DynamoDBDocumentClient, GetCommand } from "@aws-sdk/lib-dynamodb";


//1
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import UserPool from "./UserPool"
//1

const accessKeyId = process.env.REACT_APP_DYNAMO_KEY_ID;
const secretAccessKey = process.env.REACT_APP_DYNAMO_SECRET_KEY;

const client = new DynamoDBClient({
  region: "us-east-1",
  credentials: {
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey
  }
});
const docClient = DynamoDBDocumentClient.from(client);

function App() {
  const context = useContext(AppContext);
  const [loadingSdk, setLoadingSdk] = useState(true);
  const [loaded, setLoaded] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  // const [credentials, setCredentials] = useState();

  useEffect(() => {
    // Wait for useEffect below to check for logged in user first, then call dynamo
    if (!loggedIn) return;
    console.log("Logged In")

    async function callDynamo() {
      //3 Replaced FB retrieval
      // const uid = context.state.response.authResponse.userID
      // const access_token = context.state.response.authResponse.accessToken
      const uid = context.state.session.idToken.payload["custom:uid"]

      const command = new GetCommand({
        TableName: "sentiment-users",
        Key: {
          pk: `USER#${uid}`,
          sk: `#PROFILE#${uid}`
        }
      });

      const res = await docClient.send(command);
      let response = res.Item
      console.log(response)
      const access_token = response.access_token

      fetch(`https://graph.facebook.com/v18.0/${uid}/picture?access_token=${access_token}`)
        .then((fbResponse) => {
          console.log(fbResponse)
          console.log(response)
          // response["url"] = fbResponse.url
          response.url = fbResponse.url
          console.log(response)

          //4

          // context.setState(state => ({
          //   response: {
          //     ...state.response,
          //     ...response
          //   }
          // }))

          console.log(context)

          context.setState(state => ({
            ...state,
            response
          }))

          //

          console.log(context)
        })
        .catch(error => console.error(error))
    }

    callDynamo()
  }, [loggedIn])

  console.log({ context })

  useEffect(() => {
    //2 getSession starts here
    function getSession() {
      return new Promise((resolve, reject) => {
        const user = UserPool.getCurrentUser()
        if (user) {
          user.getSession((err, session) => {
            if (err) {
              console.log(err)
              reject()
            } else {
              console.log(session)
              context.setState({ session })
              setLoaded(true)
              setLoggedIn(true)
              resolve(session)
            }
          })
        } else {
          setLoaded(true)
          reject()
        }
      })


      //

    }
    // async function getLoginStatus() {
    //   window.FB.getLoginStatus(function (response) {
    //     console.log(response)
    //     setLoaded(true)
    //     if (response.status === "connected") {
    //       context.setState({ response })
    //       setLoggedIn(true)
    //       // setCredentials(response.authResponse)
    //     }
    //   })
    // }

    initFacebookSdk()
      .then(async () => {
        setLoadingSdk(false)
        //Replaced with getSession
        // getLoginStatus();
        try {
          await getSession()
          // ...
        } catch (rej) {
          // 
        }
      });
  }, [])

  if (loadingSdk) return null
  // Insert loading page here

  function handleLoginSuccess(response) {
    console.log("Logged in with access token " + response.authResponse.accessToken)
    console.log("Logged in with ID " + response.authResponse.userID)
    context.setState({ response })
    // context.state.response.authResponse
    // setCredentials(response.authResponse)
    setLoggedIn(true)
  }

  return (
    <BrowserRouter>
      <div className="app">
        <Routes>
          <Route path="/" element={<Homepage loaded={loaded} loggedIn={loggedIn} handleLoginSuccess={handleLoginSuccess} />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/verify" element={<Verify />} >
            <Route path=":email" element={<Verify />} />
          </Route>
          <Route exact path='/newsfeed' element={<PrivateRoute loggedIn={loggedIn} />}>
            <Route path="/newsfeed" element={<NewsFeed />} />
          </Route>
          <Route exact path='/review' element={<PrivateRoute loggedIn={loggedIn} />}>
            <Route path="/review" element={<Review />}>
              <Route path=":post" element={<Review />} />
            </Route>
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;